import React from "react";
import { Box } from "@chakra-ui/react";

const HeroComponent = ({ children }) => {
  return (
    <Box
      top="0"
      left="0"
      right="0"
      bottom="0"
      position="absolute"
      overflow="hidden"
    >
      {children}
    </Box>
  );
};

export default HeroComponent;
